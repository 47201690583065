#itemView {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(10, 1fr) ;

}

#itemView #logo {
    grid-column: 1 / 6;
    grid-row: 1 / 3;
    width: 200px;
}

#imageSeller {
    grid-column: 1 / 7;
    grid-row: 3 / 11;
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: 1fr 1fr;
}

#properties {
    grid-column: 7 / 15;
    grid-row: 3 / 11;
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    border: 1px solid var(--mygrey);
    border-radius: 20px;
}


#orderOwner{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    grid-column: 1 / 3;
    grid-row: 8 / 10;
    text-align: center;
}

#ownerKey{
    width: 50%;
    text-align: right;
}
#ownerValue{
    width: 50%;
    text-align: left;
    font-weight: bold;
    padding-left: 10px;
}

#itemPrice {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    color: #00cc00;
    grid-column: 1 / 3;
    font-weight: bold;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    text-align: center;
    display: flex;
}

input#itemPrice {
    text-align: left;
    height: 40px;
    width: 50%;
    margin-top: 0;
}


#itemView > #header {
    grid-column: 1 / 21;
}

#similarItemsTray {
    grid-column: 15 / 21;
    grid-row: 3 / 11;
    display: grid;
    grid-template-rows: 40px;
    grid-auto-rows: 200px;
    overflow-y: scroll;
    padding-left: 30px;
}

#similarItem {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: repeat(5, 1fr);
    overflow: clip;
    padding: 10px;
    border-radius: 20px;
}

#similarItem:hover {
    box-shadow: 0 0 4px black;
}

#similarItemPhotoHolder {
    grid-row: 2 / 5;
    grid-column: 1 / 2;
    overflow: clip;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--mygrey);
}

#similarOrdername {
    grid-row: 2 / 4;
    grid-column: 2;
    overflow: clip;
    font-size: 15px;
    font-weight: bold;
    margin-left: 50px;
}

#similarPrice {
    color: #00cc00;
    grid-row: 4 / 5;
    grid-column: 2;
    font-size: 15px;
    text-indent: 50px;
}

#similarItemPhoto {
    height: 150px;
    width: auto;
}

#similarItemsTray > #heading {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

#itemImage {
    grid-row: 1 / 7;
    grid-column: 1 / 3;
    margin: 0 50px 0 50px;
    overflow: clip;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}


#photoItem {
    height: 350px;
    width: auto;

}

#description {
    grid-row: 3 / 5;
    grid-column: 1 / 3;
    font-size: 18px;
}

#commenttray{
    grid-row: 5 / 11;
    grid-column: 1 / 3;
    display: grid;
    grid-template-rows: 1fr 5fr 1fr;
}

#commentbox {
    grid-row: 2 / 3;
    border: 1px solid var(--mygrey);
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    padding: 10px;
    overflow-y: scroll;
}

#comment {
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 1fr 8fr;

}

#profileimage {
    grid-column: 1 / 2;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

#text {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
}

#userid {
    font-weight: bold;
    font-size: 10px;
}

#commenttext {
    background-color: var(--mygrey);
    padding: 8px;
    border-radius: 10px;
    align-self: start;
    font-size: 15px;
}


#replyicons {
    display: flex;
    align-items: center;
}
#itemName {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    font-weight: bold;
}

form#itemView input,form#itemView textarea {
    border: none;
    font-family: Arial, Helvetica, sans-serif;
}

form#itemView input:focus, form#itemView textarea:focus {
    outline: none;
}

input[type="file"] {
    display: none;
}

#fileupload {
    height: 95%;
    width: 95%;
    border: 3px dashed grey;
    border-radius: 10px;
    display: flex;
    overflow: clip;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: grey;
    cursor: pointer;
}

#fileupload > img {
    height: 100%;
    width: auto;
}

label#switch {
    grid-column: 13 / 14;
    grid-row: 2;
    height: 30px;
    border-radius: 20px;
    border: solid 2px var(--mydarkergrey);
    display: grid;
    align-items: center;
}

#switchCheckbox {
    display: none;
}

#slider {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: grey;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 50%;
    background-color: var(--myred);
}

input:checked + #slider {
    justify-self: end;
    background-color: var(--myblue);
}

#itemView #sell {
    color: var(--myred);
    grid-column: 12;
    grid-row: 2;
    font-weight: bold;
    font-size: 20px;
    margin-top: 5px;
    justify-self: center;
}

#itemView #buy {
    color: var(--myblue);
    justify-self: center;
    margin-top: 5px;
    grid-column: 14;
    grid-row: 2;
    font-weight: bold;
    font-size: 20px;
}

#placeorder {
    grid-column: 2;
    grid-row: 10;
    margin: 5px;
    border-radius: 10px;
    border: none;
    background-color: black;
    color: white;
    box-shadow: 1px 1px grey;
}

#placeorder:hover {
    box-shadow: 1px 1px 5px black;
}
:root {
    --myred: #cc0000;
    --myblue: #002080;
}

#placeAnOrder {
    grid-column: 7 / 15;
    font-weight: bold;
    font-size: 40px;
}