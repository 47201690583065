.flexbox-center-aligned {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.round-input {
    border: 1px solid var(--mydarkergrey);
    border-radius: 20px;
    height: 35px;
    text-indent: 30px;
    outline: none;
}

.no-border-bgcolor {
    border: none;
    background-color: white;
}

.img-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: clip;
}

.popupitem {
    display: flex;
    width: 100%;
    height: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid var(--mygrey);
}

.popupitem:hover {
    background-color: var(--mygrey);
}