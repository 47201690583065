:root {
    --pass-outline-color: blue;
}
#LoginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6f0ff;
    height: 100vh;
    width: 100vw;
}

#LoginPage>form {
    border-radius: 20px;
    background-color: white;
    height: 60%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px #bfbfbf;
}

#LoginPage input, #LoginPage select{
    height: 40px;
    border: 0.5px solid #0066ff;
    border-radius: 10px;
    width: 55%;
    margin-bottom: 20px;
    font-size: 1.1em;
    text-indent: 10px;
    
}

#LoginPage select {
    background-color: white;
    text-indent: 5px;
}

#LoginPage input:hover {
    box-shadow: 0 0 2px #0066ff;
}

#LoginPage input:focus {
    outline: 2px solid blue;
}

#LoginPage input#password:focus, #LoginPage>input#cpassword:focus {
    outline: 2px solid var(--pass-outline-color);
    border-color: var(--pass-outline-color);
}
.logintext {

    width: 55%;
    text-indent: 5px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    color: blue;
}

#warnings {
    width: 55%;
    text-align: 5px;
    margin-top: 2%;
    font-size: 14px;
    color: red;
    height: 20px;
}

#LoginPage>form > button {
    width: 150px;
    height: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 1%;
    margin-bottom: 3%;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    box-shadow: 0 0 3px #bfbfbf;
    border-radius: 15px;
    background-color: white;
    color: grey;
    font-weight: 1000;
}

#LoginPage>form > button:hover {
    box-shadow: 0 0 5px #0000ff;
}

form#signup {
    height: 90%;
}

form#signup > button {
    width: 200px;
}

#LoginPage input[type=checkbox] {
    height: 15px;
    width: 15px;
}

div#showpass {
    width: 55%;
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
}

div#switch {
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
}